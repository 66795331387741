@font-face {
    src: url("../fonts/cocogoose-pro.regular.ttf");
    font-family: Cocogoose-Pro;
}

@font-face {
    src: url("../fonts/cocogoose-pro.semilight.ttf");
    font-family: Cocogoose-Pro-Semilight;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Book.otf");
    font-family: Avenir-Book;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Medium.otf");
    font-family: Avenir-Medium;
}

@font-face {
    src: url("../fonts/AvenirLTStd-Roman.otf");
    font-family: Avenir-Roman;
}

body {
    background-color: #FAFAFA !important;
    font-family: "Avenir-Book", sans-serif;
}

/* Button */
.ui.button {
    border-radius: .15rem !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: 900 !important;
    padding: 12px !important;
    min-width: 133px !important;
    letter-spacing: .12em !important;
}

@media (min-width: 992px) {
    .ui.icon.left.labeled {
        margin: 0 0 0 10px;
    }
}

.ui.button.primary {
    background: #078ec9 !important;
    border: 1px solid transparent;
}

.ui.button.primary:hover {
    border: 1px solid #078ec9;
    background: transparent !important;
    color: #078ec9;
}

.ui.button.positive {
    background: #9ce55d !important;
    border: 1px solid transparent;
}

.ui.button.positive:hover {
    background: transparent !important;
    color: #9ce55d;
    border: 1px solid #9ce55d;
}


.sidenav .collapsible .nav-list {
    margin-top: 15px;
}

.sidenav .collapsible .checkbox label:after {
    display: none !important;
}

/* Modal */
.modal {
    padding-bottom: 0 !important;
}

.modal .negative {
    background-color: transparent !important;
    border: 1px solid #078ec9 !important;
    color: #078ec9 !important;
}

.modal .negative:hover {
    background-color: #078ec9 !important;
    color: #fff !important;
}

.mini-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    transform: translateY(-250px);
    overflow: auto !important;
    bottom: auto !important;
}

.rule-modal {
    transform: translateY(-450px) !important;
}

.ui.modal .header {
    padding-bottom: 30px !important;
    padding-top: 0px !important;
    margin-top: -40px !important;
    font-size: 18px !important;
}

.ui.modal .actions {
    background-color: transparent !important;
}

.modal input, .modal .ui.form .field>.selection.dropdown {
    height: 40px !important;
    font-size: 15px !important;
    border-radius: 0 !important;
}

.modal textarea {
    font-size: 15px !important;
    border-radius: 0 !important;
}

.modal input:not([disabled]) {
    margin-bottom: 15px !important;
}

.modal .ui.form .field {
    position: relative;
}

.modal .ui.form .field>.selection.dropdown {
    min-height: 40px !important;
    padding-top: 15px !important;
}

.modal .ui.form .field>.multiple.selection.dropdown {
    padding-top: 5px !important;
}

.modal .ui.form .fields {
    margin: 13px 0 !important;
}

.modal .ui.form .field>.selection.dropdown {
    margin: 0 0 13px 0;
}

.modal .ui.form .fields:nth-child(1), .modal .ui.form .field:nth-child(2) {
    margin: 0 !important;
}

.modal label {
    font-size: 13px !important;
}

.ui.selection.dropdown .menu>.item {
    padding: 10px 15px !important;
}

.ui.form .fields>.field {
    padding-left: 0 !important;
}

.ui.form .fields>.field h4 {
    font-weight: bold;
}

.ui.form .fields {
    margin: 1rem 0;
}

.ui.form .fields>.field:last-child {
    padding-right: 0 !important;
}

.ui.selection .item .text {
    font-size: 15px !important;
}

header, .header {
    height: 0 !important;
}

.formik-error-msg {
    color: #078ec9;
    font-size: 13px;
    position: absolute;
    left: 0;
    bottom: -8px;
}

.formik-error-msg-bankMng {
    color: #078ec9;
    font-size: 13px;
}

.login-formik-error-msg {
    color: #078ec9;
    font-size: 13px;
}

/* Navbar */
header.header-6, .header.header-6 {
    background-color: #078ec9 !important;
}

header .header-nav .nav-link:first-of-type::before, header .header-nav .nav-link:last-of-type::after, .header .header-nav .nav-link:first-of-type::before, .header .header-nav .nav-link:last-of-type::after {
    display: none !important;
}

header .settings .nav-link, header .header-nav .nav-link, header .header-actions .nav-link, .header .settings .nav-link, .header .header-nav .nav-link, .header .header-actions .nav-link {
    color: #ffffff !important;
    opacity: 1 !important;
    font-family: "Avenir-Medium", sans-serif;
    font-size: 16px;
}

.ui.dropdown .menu {
    border-radius: 0 !important;
}

.ui.dropdown .menu>.item {
    font-size: .88rem;
}

.ui.dropdown .menu .item span {
    color: #000000 !important;
    text-decoration: none !important;
}

.ui.dropdown .menu .item {
    padding: 8px 20px !important;
}

.ui.dropdown .menu .item:hover {
    background-color: #078ec9;
}
.ui.dropdown .menu .item:hover span, .ui.dropdown .menu .item:hover {
    color: #ffffff !important;
}


.ui.dropdown .menu a {
    text-decoration: none !important;
}

.user-dropdown {
    padding: 0 0 20px 0 !important;
    width: 300px !important;
    text-align: center !important;
}

.user-dropdown div {
    background-color: #078ec9;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1rem;
    padding: 15px 30px;
    text-align: left !important;
}

.nav-icon i {
    font-size: 1.2rem !important;
    margin-left: -5px;
}

/* Side Menu */
.divider {
    margin: 1rem 0 0 0 !important;
}

.ui.table {
    border-radius: 0 !important;
    margin-top: 1.5rem !important;
}

.sidemenu {
    padding: 1rem 60px 1rem 10px;
    background: #FAFAFA;
    min-height: 100%;
    z-index: 9999;
    text-align: center;
    display: none;
}

.sidemenu.opened {
    display: block;
}

.sidemenu h4 {
    margin: 0 0 20px 0 !important;
    font-size: 17px;
    text-align: left;
}

.sidemenu p {
    color: #000000 !important;
    text-align: left;
    font-weight: bold;
}

@media (max-width: 768px) {
    .sidemenu {
        width: 100%;
    }
}

.collapser {
    position: absolute;
    right: -1px;
    width: 25px;
    margin-top: -2px;
    background: #FAFAFA;
    height: 100%;
    border-top: 1px solid #cccccc;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.collapser i {
    margin-top: -200px;
    margin-left: 5px;
}

.collapser.toggled {
    display: none;
}

.collapser2 {
    width: 25px;
    margin-top: -2px;
    background: #FAFAFA;
    height: 100%;
    border-top: 1px solid #cccccc;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding-left: 6px;
}

/* Subnav */
.react-tabs-container {
    border: none !important;
    background-color: #FAFAFA !important;
    height: 45px !important;
}

.react-tabs-tab {
    font-size: 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #EEEEEE !important;
    border: 1px solid #D7D7D7 !important;
    border-bottom: 1px solid #D7D7D7 !important;
    border-left: none !important;
    color: #000000 !important;
}

.react-tabs-active {
    border-bottom: none !important;
    background-color: #FAFAFA !important;
    color: #078ec9 !important;
}

.react-tabs-tab-close {
    border: none !important;
    visibility: visible !important;
    line-height: 0 !important;
    background: none !important;
    cursor: pointer !important;
}

.react-tabs-child {
    padding: 0 !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    border-bottom: 1px solid #D7D7D7 !important;
}

.content-area {
    padding-top: 0 !important;
}

.sidenav {
    min-height: 100vh !important;
}

.sidenav h3 {
    font-family: "Cocogoose-Pro-Semilight", sans-serif;
    font-size: 18px;
}

.reports-container .content-area {
    padding-top: 1.2rem !important;
}

.content-area h2 {
    font-family: "Cocogoose-Pro-Semilight", sans-serif;
    font-size: 25px;
}

/* Table */
.ui.pagination.menu .active.item {
    background-color: #078ec9 !important;
    color: #fff !important;
}

.ui.table tfoot tr:first-child>th:only-child {
    text-align: center;
}

.ui.table .pressed {
    background-color: #078ec9;
    color: #ffffff;
}

.splitter-layout {
    overflow-x: hidden !important;
}

.splitter-layout .layout-pane.layout-pane-primary {
    padding-right: 20px !important;
}

.splitter-layout > .layout-splitter {
    display: none !important;
}

.content-container input {
    height: 50px;
}

/* Header Content */
.buttons-group-col, .buttons-group-col-2 {
    text-align: right;
}

@media (max-width: 992px) {
    .buttons-group-col, .buttons-group-col-2 {
        text-align: left;
        margin-top: -30px;
    }
}

/* Spinner */
.spinner-container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.table-overflow {
    overflow: auto;
    width: 100%;
    margin-top: -1.5rem;
}

.ui.styled.accordion, .ui.styled.accordion .accordion {
    border-radius: 0;
    margin-top: 37px;
}

.ui.accordion .title:not(.ui) {
    font-size: 17px;
}

.ui.styled.accordion .active.title {
    border-bottom: 1px solid rgba(34,36,38,.15);
}

.control-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-bottom: 1px solid rgba(34,36,38,.15);
}

.control-btn {
    border: none !important;
    background: transparent !important;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.control-btn:first-child {
    border-right: 1px solid rgba(34,36,38,.15) !important;
}

.control-btn:last-child {
    border-left: 1px solid rgba(34,36,38,.15) !important;
}

.control-btn:focus {
    outline: none !important;
}

.control-btn:hover {
    color: #078ec9 !important;
}

.ui.secondary.pointing.menu .item {
    padding: 20px 40px !important;
}

.ui.secondary.pointing.menu .active.item {
    color: #078ec9 !important;
    border-color: #078ec9 !important;
}

.ui.segment.tab {
    border-radius: 0 !important;
}

.reports-container .ui.table tfoot tr:first-child>th:only-child {
    text-align: left;
}

.modal .ui.form .fields.noMargin {
    margin: 0 !important;
}

.system-alerts-table .ui.table {
    white-space: pre-wrap;
}

.system-alerts-table .ui.table tr td:nth-child(4) {
    width: 30% !important;
}

.system-alerts-table .ui.table tr td:nth-child(5) {
    width: 100% !important;
}

.permissions-accord {
    margin-top: 185px !important;
    width: 100% !important;
}


/* User Details */
.info {
    border: 1px solid #ddd;
}

.info-header {
    background-color: #eee;
    padding: 15px 15px;
    border-bottom: 1px solid #ddd;
}

.info-header h4 {
    font-size: 23px;
    font-weight: bold;
}

.info-item {
    display: flex;
}

.info-item p {
    margin: 0 !important;
    font-size: 18px;
    padding: 0 15px;
    min-height: 80px;
    display: flex;
    align-items: center;
}

.info .first-col, .info .second-col {
    width: 50%;
}

.info .first-col p {
    border-bottom: 1px solid #ddd;
}

.info .second-col p {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.info-item p:last-child {
    border-bottom: none !important;
}

.user-modal {
    transform: translateY(-400px);
}

@media (max-height: 768px) {
    .modals.dimmer .ui.scrolling.modal {
        position: relative !important;
        margin: 0 !important;
        top: 50px;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

@media (max-height: 900px) {
    .rule-modal {
        transform: translateY(-350px) !important;
    }
}

/* Rules */
.rule-summary-header {
    background-color: #ddd;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.rules-summary h4 {
    font-size: 17px;
    margin: 10px 0 !important;
}

.rules-summary h4 strong {
    font-weight: 600 !important;
}
.mno .ui.selection.active.dropdown .menu{
    height: 200px;
}