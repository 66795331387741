.view {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.view .form-container {
    position: relative;
    background: white;
    width: 500px;
    padding: 50px;
    margin: 0 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    z-index: 100;
}

.view .form-container .form-container-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.view .form-container h4 {
    font-weight: 900;
    font-size: 30px;
    margin-left: 15px;
    margin-bottom: 15px;
}

.view .form-container button {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    font-size: 15px !important;
}

.view img.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
